import axios from 'axios'

// Dynamic base url according to location hostname
// Add Other country and brand name domain name
//PROD
var config = {
  elConfig:{
    baseURL: 'https://lzd.esteelauder.com.sg/'
  },
  bbConfig:{
    baseURL: 'https://lzd.bobbibrown.com.sg/'
  },
  lmConfig:{
    baseURL: 'https://lzd.lamer.com.sg/'
  },
  orConfig:{
    baseURL: 'https://lzd.origins.com.sg/'
  },
  lsConfig:{
    baseURL: 'https://lzd.labseries.com.sg/'
  },
  delmConfig:{
    baseURL: 'https://lzd.delamer.com.sg/'
  },
}


// var config = {
//   elConfig:{
//     baseURL: 'http://zd.esteelauder.com.sg/'
//   },
//   bbConfig:{
//     baseURL: 'http://localhost:3000/'
//   },
//   lmConfig:{
//     baseURL: 'http://localhost:3000/'
//   },
//   orConfig:{
//     baseURL: 'http://localhost:3000/'
//   },
//   lsConfig:{
//     baseURL: 'http://localhost:3000/'
//   }
// }

function getConfig(){
  console.log("test")
  // switch base url according to hostname
  switch(window.location.hostname.split('.')[1]){
    case 'esteelauder':
      return config.elConfig;
    case 'bobbibrown':
      return config.bbConfig;
    case 'lamer':
      return config.lmConfig;
    case 'delamer':
      return config.delmConfig;
    case 'origins':
      return config.orConfig;
    case 'labseries':
      return config.lsConfig;
    default:
      return config.elConfig;
  }
}


// create an axios instance
const service = axios.create({
  // baseURL: "https://app-ap-southeastasia-uat-cef-eformrl.ase-ap-southeastasia-nonprod-shared.ap.elcompanies.net/", // url = base url + request url
  baseURL: getConfig().baseURL, // url = base url + request url
    // baseURL: "http://10.126.22.24:3000/", // url = base url + request url
  // baseURL: "https://consumereformuat.elcompanies.com/",
  // baseURL: "https://lzd.esteelauder.com.sg/",
  // baseURL: "https://consumereformuat.elcompanies.com/",
  // baseURL: "/test", // url = base url + request url
  //withCredentials: true, // send cookies when cross-domain requests
  timeout: 300000 // request timeout

})
 
// request interceptor
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    // do something with request errorx

    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const status = response.status
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (status !== 200) {
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  async error => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default service
